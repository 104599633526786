import React, { useEffect, useContext, useState } from "react";
import logo from "../../../assets/images/user/logo.png";
import gameOverImg from "../../../assets/images/game-over-img.png";
import timesUpImg from "../../../assets/images/timeup.jpg";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../Admin/Config/config";
import { SocketContext } from "../../../contexts/socketConnection";
import Countdown from "react-countdown";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { date } from "yup";
import { getPodName, leaderBoardStatus } from '../Services/Service';

import useInterval from "../../../hooks/useInterval"



export default function GameFinishedStatus({ timeOver, totalPodTime, leaderStatus }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const { token, gameId, podId } = useParams();
    const socket = useContext(SocketContext);
    const [gameStatus, setGameStatus] = useState(null)
    const [completed, setCompleted] = useState("")
    const [show, setShow] = useState(localStorage.getItem("completed") ? localStorage.getItem("completed") : false)
    const [leader, setLeader] = useState(false)


    const [endTimeRes, setEndTime] = useState(0)
    const [time, setTime] = useState(0)

    let [msg, setMsg] = useState("")
    const [leaderRes, setLeaderRes] = useState({})
    const [refresh, setRefresh] = useState()
    console.log("data", leaderStatus);
    // console.log("endTime", podId);




    const reduxlabelData = useSelector((state) =>
        state.game.data &&
            state.game.data.labelData &&
            Object.keys(state.game.data.labelData).length
            ? state.game.data.labelData
            : {}
    );

    const [labelData, setLabelData] = useState(reduxlabelData)

    useEffect(async () => {
        const func = async () => {
            await getLabelData()
        }
        if (Object.keys(labelData).length == 0) {
            document.body.classList.add("loading-indicator");
            func()
        }
        // document.body.classList.remove("loading-indicator");
    }, [])
    const getLabelData = async () => {
        const res = await getPodName({ _id: podId, gameId: gameId });
        const data = res?.data?.labelData
        setLabelData(data);
        document.body.classList.remove("loading-indicator");
    }


    function formatTime(endTime) {
        if (typeof endTime !== 'number') {
            throw new Error('The endTime argument must be a number representing milliseconds.');
        }

        const currentTime = Date.now(); // Get the current time in milliseconds
        const timeDifferenceInMilliseconds = endTime - currentTime;
        const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
        return Math.ceil(timeDifferenceInMinutes);
    }

    useInterval(() => {
        if (time <= 1 && leader === false) {
            getData()
            setTime(0)
        } else {
            setTime(formatTime(endTimeRes))
        }
    }, 10000);

    useEffect(() => {
        socket.emit('update', {
            podId
        })

        let rs = (data) => {
            if (data?.leaderboardstatus) {
                // if (data?.teams) setGameStatus(data?.teams?.[0].gameStatus)
                setLeader(data?.leaderboardstatus)
            }
        }
        socket.on("refreshStatus", rs)
        return () => {
            socket.off("refreshStatus", rs)
        }
    }, [])

    useEffect(() => {
        document.body.classList.add("loading-indicator");
        setLoading(true)
        getData()
    }, [])

    const getData = async () => {
        try {
            let body = {
                token: token,
                gameId: gameId,
                podId: podId
            }
            const res = await leaderBoardStatus(body);
            console.log("first", res.data.data);
            setMsg(res?.data?.data?.message)
            setLeader(res.data.data.leaderboardstatus)
            setGameStatus(res.data.data?.teams?.gameStatus)
            setLeaderRes(res.data.data)


            socket.emit("refreshStatus", {
                token: token,
                gameId: gameId,
                podId: podId,
                leaderboardstatus: res.data.data.leaderboardstatus
            })

            if (res?.data?.data.leaderBoardStatus) {
                socket.emit("refreshStatus", {
                    token: token,
                    gameId: gameId,
                    podId: podId,
                    leaderboardstatus: res.data.data.leaderboardstatus
                })
            }

            if (res?.data?.data?.podEndTime > Date.now()) {
                setEndTime(res.data.data.podEndTime)
                setTime(formatTime(res?.data?.data?.podEndTime))
            }

        } catch (err) {
            console.log(err);
        } finally {
            document.body.classList.remove("loading-indicator");
            setLoading(false)
        }
    }
    const handleLeaderBoard = () => {
        navigate(`/user/${token}/${gameId}/${podId}/leaderboard`, { state: { replace: true } });
    };


    return (
        <div className="game-over-screen-bg">
            <div className="container pt-5">
                <div className="form-bg-inner">
                    <div className="logo text-center">
                        <img width="320px" src={logo} alt="Logo" />
                    </div>
                    {!loading && (
                        <div className="game-over-screen">
                            <figure className="game-over-screen-img">
                                <img src={(timeOver || gameStatus === 3) && gameStatus !== null
                                    ? (labelData?.gameTimeoutImage ? url + labelData?.gameTimeoutImage : url + "public/admin/default/timeup.jpg")
                                    : (labelData?.gameCompletedImage ? url + labelData?.gameCompletedImage : url + "public/admin/default/gameCompleted.png")}
                                    alt="Game Over" />
                            </figure>
                            <figcaption className="game-over-screen-content">
                                <h2 className="pb-2">
                                    {timeOver || gameStatus === 3 ? labelData?.gameTimeoutHeader : labelData?.gameCompleteLabel}
                                </h2>
                                <p className="para-leaderboard" dangerouslySetInnerHTML={{ __html: timeOver || gameStatus === 3 ? labelData?.gameTimeoutText : labelData?.gameCompleteDesc }}></p>
                            </figcaption>
                            {(leaderStatus === true || leader === true) ? (
                                <button className="styledBtn px-btn mb-4" onClick={handleLeaderBoard}>
                                    <span>{labelData?.ViewLeaderboardButton}</span>
                                </button>
                            ) : (
                                <button className="styledBtn styledBtn-disabled disabled mb-4 px-btn" onClick={getData} disabled={loading}>
                                    <span>{labelData?.ViewLeaderboardButton}</span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
