function StarIcon({ width }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            data-name='Layer 1'
            viewBox='0 0 88.64 88.64'
            style={{ width }}
        >
            <path
                d='M44.32 0C19.88 0 0 19.88 0 44.32s19.88 44.32 44.32 44.32 44.32-19.88 44.32-44.32S68.76 0 44.32 0zm0 80.58c-19.99 0-36.26-16.27-36.26-36.26S24.33 8.06 44.32 8.06s36.26 16.27 36.26 36.26-16.27 36.26-36.26 36.26z'
                className='cls-1'
                fill="#fffb00"
            ></path>
            <circle cx='44.32' cy='44.32' r='36.26'></circle>
            <path
                d='M66.05 37.18a2.341 2.341 0 00-2-1.6l-12.61-1.14-4.99-11.67c-.37-.86-1.2-1.41-2.14-1.41s-1.77.55-2.14 1.41l-4.99 11.67-12.61 1.14a2.33 2.33 0 00-1.32 4.07l9.53 8.36-2.81 12.38c-.21.91.15 1.85.9 2.4.41.29.88.44 1.36.44.41 0 .82-.11 1.19-.33l10.88-6.5 10.87 6.5a2.318 2.318 0 003.45-2.51l-2.81-12.38 9.53-8.36c.7-.61.97-1.58.68-2.47h.03z'
                className='cls-2'
                fill="#fff"
            ></path>
        </svg>
    );
}

export default StarIcon;