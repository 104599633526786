import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { DatePicker } from '@mui/lab'
import moment from 'moment';
import { editPod, getOnePod } from '../Services/PodServices';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Box, FormHelperText, Grid, InputLabel, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import { getGameName } from '../Services/GameServices';
import { addTimeToDate, getDateFormat, getPSTDate } from '../../../utils/formatNumber';

const EditPod = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [gameData, setGameData] = useState([]);
  const [data, setData] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);

  useEffect(() => {
    getGameData();
  }, []);

  const getGameData = async () => {
    const result = await getGameName();
    setGameData(result?.data.data);
  };


  const PodSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').strict().trim("Trailing white spaces not allowed").min(1).max(60),
    // email: Yup.string().nullable().optional().matches(/^(?!\s+$).*/, "Spaces are not allowed").test("is-email", "Please enter valid email", (val) => {
    //   return val == undefined || complexEmailRegex(val)
    // }).max(255),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(?:,[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/, 'Invalid email format'),
    totalTeams: Yup.number().min(1, "Total teams must be greater than or equal to 1").max(50, "Total teams must be less than or equal to 50").required('Total team is required'),
    gameId: Yup.string().required('Please select a game'),
    date: Yup.date().required('Start date is required').typeError("Please enter a valid date"),
    minutes: Yup.number().required("Minutes is required").strict().moreThan(0, "Please enter valid minutes"),
    time: Yup.object().shape({
      hours: Yup.string().required("Hours is required").test(
        'Is positive?',
        'Hours must be greater than or equals to zero',
        (value) => value >= 0
      ),
      minutes: Yup.string().required("Minutes is required").test(
        'Is positive?',
        'Minutes must be greater than or equals to zero',
        (value) => value >= 0
      ),
      format: Yup.string().required("Please select time format")
    })
  });


  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      id: state._id,
      name: "",
      email: "",
      totalTeams: "",
      gameId: "",
      date: new Date(),
      minutes: "",
      minDate: new Date(),
      todayDate: new Date(),
      time: {
        hours: '12',
        minutes: '00',
        format: "AM"
      },
    },

    validationSchema: PodSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let timeValue = { ...values.time };

        let d = addTimeToDate(getDateFormat(values.date), timeValue);
        const sendData = {
          _id: values.id,
          name: values.name,
          email: values.email,
          totalTeams: values.totalTeams,
          gameId: values.gameId,
          date: d,
          time: timeValue,
          minutes: values.minutes,
        }
        const respData = await editPod(sendData);

        if (respData.status === 200) {
          navigate('/admin/pod');
          toast.success(respData?.data.message);
        }

      } catch (error) {
        console.error(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;


  const getData = async () => {
    const resp = await getOnePod({ _id: state._id });

    let data = resp.data.data;

    setValues({
      ...values,
      name: data.name,
      email: data.email,
      totalTeams: data.totalTeams,
      gameId: data.gameId._id,
      minutes: data.minutes,
      gameLink: data.gameLink,
      date: data.date,
      minDate: data.date,
      time: { ...data.time }

    });
    setData(true)
  }

  useEffect(() => {
    getData()
  }, [])


  const handleDateChange = (d) => {
    setValues({
      ...values,
      date: d
    })
  }
  const handleTimeDuration = (e) => {
    let v = e.target.value;
    let n = e.target.name;


    if (Number(v) === NaN || Number(v) === null || Number(v) === undefined) {
      setFieldValue(e.target.name, 0)
    } else if (Number(v) < 0) {
      setFieldValue(e.target.name, 0)
    } else {
      setFieldValue(e.target.name, parseInt(e.target.value))
    }
  }
  const handleChangeTime = (e) => {
    let elementName = e.target.name;
    let elementValue = e.target.value;
    let timeReg = /^[0-59]$/
    if (elementName !== 'format') {
      if (elementValue !== "" && elementValue !== null && elementValue !== undefined) {

        if (elementName === 'hours') {
          if (parseInt(elementValue) >= 12) {
            elementValue = 12;
          }

        }
        if (elementName === 'minutes') {
          if (parseInt(elementValue) >= 59) {
            elementValue = 59;
          }

        }
      }
    }
    setValues({
      ...values,
      time: {
        ...values.time,
        [elementName]: elementValue
      }
    })

  }
  return (
    <>
      {
        <Card>
          <Card.Header>
            <Card.Title as="h5"> Edit Pod</Card.Title>
          </Card.Header>
          <Card.Body>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Pod Name</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="name"
                      placeholder='Enter POD name'
                      // value={values?.name}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      {...getFieldProps('name')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Minutes*</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      placeholder='Enter Minutes'
                      value={values.minutes}
                      name="minutes"
                      type="number"
                      error={Boolean(touched.minutes && errors.minutes)}
                      helperText={touched.minutes && errors.minutes}
                      onWheel={event => event.target.blur()}
                      onChange={(e) => { handleTimeDuration(e) }} />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Start Date*</InputLabel>
                    <FormControl fullWidth error={Boolean(touched.date && errors.date)}>
                      <DatePicker
                        minDate={new Date('December 01, 2021 03:24:00')}
                        name="date"
                        open={dateOpen}
                        onOpen={() => setDateOpen(true)}
                        onClose={() => setDateOpen(false)}
                        inputFormat='MM/d/yyyy'
                        value={values.date}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField onClick={() => { setDateOpen(true) }} onKeyDown={(e) => { e.preventDefault(); }} variant='outlined' {...params} />}
                      />
                      <FormHelperText>{touched.date && errors.date}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Start Time*</InputLabel>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="hours"
                          type="number"
                          placeholder='HH'
                          value={values.time.hours}
                          error={Boolean(touched?.time?.hours && errors?.time?.hours)}
                          helperText={touched?.time?.hours && errors?.time?.hours}
                          onChange={(e) => { handleChangeTime(e) }}
                          onWheel={event => event.target.blur()}
                        />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="minutes"
                          type="number"
                          placeholder='MM'
                          value={values.time.minutes}
                          error={Boolean(touched?.time?.minutes && errors?.time?.minutes)}
                          helperText={touched?.time?.minutes && errors?.time?.minutes}
                          onChange={(e) => { handleChangeTime(e) }}
                          onWheel={event => event.target.blur()}
                        />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <FormControl fullWidth error={Boolean(touched?.time?.format && errors?.time?.format)}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant='outlined'
                            name='format'
                            value={values.time.format}
                            onChange={(e) => { handleChangeTime(e) }}
                          >
                            <MenuItem value="AM">AM</MenuItem>
                            <MenuItem value="PM">PM</MenuItem>

                          </Select>
                          <FormHelperText>{touched?.time?.format && errors?.time?.format}</FormHelperText>

                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Select Game*</InputLabel>
                    <FormControl fullWidth error={Boolean(touched.gameId && errors.gameId)}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant='outlined'
                        label="Select Game"
                        displayEmpty
                        disabled
                        value={values.gameId}
                        {...getFieldProps('gameId')}
                      >
                        {gameData.length ?
                          <MenuItem disabled value="">Select Game</MenuItem>
                          : null
                        }
                        {gameData.map((data, idx) => (
                          <MenuItem key={idx} value={data._id}>{data.internalTitle}</MenuItem>
                        ))}

                      </Select>
                      <FormHelperText>{touched.gameId && errors.gameId}</FormHelperText>

                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Total Teams*</InputLabel>
                    <TextField
                      fullWidth
                      variant='outlined'
                      type="number"
                      name="totalTeams"
                      placeholder='Enter Total teams'
                      // onChange={(e)=>{handleChangeTeams(e)}}
                      value={values.totalTeams}
                      {...getFieldProps('totalTeams')}
                      error={Boolean(touched.totalTeams && errors.totalTeams)}
                      helperText={touched.totalTeams && errors.totalTeams}
                      onWheel={event => event.target.blur()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      placeholder='Enter email'
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      {...getFieldProps('email')}
                    />
                  </Grid>

                </Grid>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                  <Button type="reset" onClick={() => { navigate("/admin/pod") }} className="btn btn-outline-secondary mr-3">
                    BACK
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    SAVE
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Card.Body>
        </Card>
      }
    </>
  );
};

export default EditPod;

