import React from 'react'
import AdminLayout from '../layouts/AdminLayout';
import { useRoutes, Navigate } from 'react-router-dom'


import Dashboard from '../Screens/Admin/Dashboard/Dashboard';
import Pod from '../Screens/Admin/PodManagement/Pod';
import ContentManagement from '../Screens/Admin/ContentManagement';
import Chapter from '../Screens/Admin/ChapterManagement/Chapter';
import Leaderboard from '../Screens/Admin/Leaderboard/Leaderboard';
import Game from '../Screens/Admin/GameManagement/Game';
import GameList from '../Screens/Admin/GameManagement/GameList';
import EditGame from '../Screens/Admin/GameManagement/EditGame';
import ChapterList from '../Screens/Admin/ChapterManagement/ChapterList';
import OneGameView from '../Screens/Admin/GameManagement/OneGameView';
import ChapterOneList from '../Screens/Admin/ChapterManagement/ChapterOneList';
import EditChapter from '../Screens/Admin/ChapterManagement/EditChapter/EditChapter';
import AddPod from '../Screens/Admin/PodManagement/AddPod';
import PodOneView from '../Screens/Admin/PodManagement/PodOneView';
import EditPod from '../Screens/Admin/PodManagement/EditPod';
import TeamList from '../Screens/Admin/TeamManagement/TeamList';
import TeamAdd from '../Screens/Admin/TeamManagement/TeamAdd';
import TeamOneView from '../Screens/Admin/TeamManagement/TeamOneView';
import EditTeam from '../Screens/Admin/TeamManagement/EditTeam';
import Report from '../Screens/Admin/Report/Report';
import Trash from '../Screens/Admin/TrashGame/Trash'
import LabelList from '../Screens/Admin/LabelManagement/List';
import LabelAdd from '../Screens/Admin/LabelManagement/Add';
import LabelEdit from '../Screens/Admin/LabelManagement/Edit';
import LabelView from '../Screens/Admin/LabelManagement/View';
import LeaderBoard from '../Screens/Admin/PodManagement/LeaderBoard';
import UserLeaderBoard from "../Screens/User/UserGame/LeaderBoard";
// import Home from './Home';


export default function AdminRoutes() {
  return useRoutes([
    {
      path: 'admin',
      element: <AdminLayout />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: "pod", element: <Pod /> },
        { path: "cms", element: <ContentManagement /> },
        { path: "addpod", element: <AddPod /> },
        { path: "editpod", element: <EditPod /> },
        { path: "podoneview", element: <PodOneView /> },
        { path: "pod/:podId/leaderboard", element: <LeaderBoard /> },

        { path: "leaderboard", element: <Leaderboard /> },
        { path: "game", element: <Game /> },
        { path: "gamelist", element: <GameList /> },
        { path: "game/edit/:gameId", element: <EditGame /> },
        { path: "onegameview", element: <OneGameView /> },
        { path: "teamlist", element: <TeamList /> },
        { path: "addteam", element: <TeamAdd /> },
        { path: "editteam", element: <EditTeam /> },
        { path: "teamview", element: <TeamOneView /> },
        { path: "reports", element: <Report /> },

        { path: "trash", element: <Trash /> },

        { path: "chapter", element: <Chapter /> },
        { path: "chapter/list/:gameId", element: <ChapterList /> },
        { path: "editchapter", element: <EditChapter /> },
        { path: "chapter/:gameId/:id", element: <ChapterOneList /> },

        { path: "label/list", element: <LabelList /> },
        { path: "label/add", element: <LabelAdd /> },
        { path: "label/edit/:id", element: <LabelEdit /> },
        { path: "label/view/:id", element: <LabelView /> },
        { path: '', element: <Navigate to="/admin/dashboard" replace /> },
        // { path: '*', element: <Navigate to="/admin/dashboard" replace /> },

      ]
    },
    // { path: '/', element: <RedirectDomain /> },
    // { path: '/', element: <Navigate to="/admin/dashboard" replace /> },
    { path: "user/:token/:gameId/:podId/leaderboard", element: <UserLeaderBoard /> },
    { path: '*', element: <Navigate to="/admin/dashboard" replace /> },
  ])
}


// const RedirectDomain = () => {
//   window.location.assign('http://escapely.com');
//   return null
// }