import axios from "axios";



export default (history = null) => {

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(function (config) {
    // console.log("req start")
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator

    // Do something before request is sent
    if (!config.url.includes("/getadminLeaderBoard")) {
      document.body.classList.add('loading-indicator');
    }

    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      document.body.classList.remove('loading-indicator');
      // console.log("got response");
      return response;
    },
    (error) => {
      console.log(error.response);
      document.body.classList.remove('loading-indicator');
      if (error.response.status === 401) {
        // do something
        // console.log("NOT FOUND");
        localStorage.clear();
        if (history) {
          history.push("/login");
        } else {
          window.location = "/login";
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
