import axios from 'axios';
import { Base_URL } from '../Config/Config';
import Interceptor from '../../../config/Interceptor';


export const getPodName = (_id) => {
  return axios.post(`${Base_URL}/admin/pods/getPodName`, _id);
};

export const addTeamMember = (data) => {
  return Interceptor().post(Base_URL + "/user/games/addTeamMember", data)
}

export const isPlayed = (data) => {
  return Interceptor().post(Base_URL + "/user/games/isplayed", data)
}


export const addTeamName = (data) => {
  return Interceptor().post(Base_URL + "/user/games/addTeamName", data)
}

export const getTeamName = (data) => {
  return Interceptor().post(Base_URL + "/user/games/getTeamName", data)
}

export const getGameDetails = (data) => {
  return Interceptor().post(Base_URL + "/user/games/getGameDetails", data)
}

export const getOneChapterDetails = (data) => {
  return Interceptor().post(Base_URL + "/user/games/getOneChapterDetails", data)
}

export const getContent = () => {
  return axios.post(Base_URL + "/user/games/getOneContentDetails")
}

export const leaderBoardStatus = (data) => {
  return axios.post(Base_URL + "/user/games/getLeaderBoardStatus", data)
}


export const checkValidUrl = (data) => {
  return axios.post(Base_URL + "/user/games/checkValidUrl", data)
}


export const checkValidLeaderboard = (data) => {
  return axios.post(Base_URL + "/user/games/checkValidLeaderboard", data)
}


export const GetLeaderBoard = (data, token) => {
  return axios.post(Base_URL + "/admin/pods/getLeaderBoard", data)
}

