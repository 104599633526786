import React from 'react'
import { useSelector } from 'react-redux';
import AdminRoutes from './adminRoutes';
import LoginRoutes from './loginRoutes';

import { socket, SocketContext } from '../contexts/socketConnection'

export default function Routing() {
    let authToken = useSelector(state => state.login.data && state.login.data.token ? state.login.data.token : null);
    if (window.location.pathname === '/') {
        window.location.assign('http://escapely.com');
        return null
    }
    return (
        <>
            {authToken !== null && authToken !== undefined && authToken !== "" ?
                <AdminRoutes />
                :
                <SocketContext.Provider value={socket}>
                    <LoginRoutes />
                </SocketContext.Provider>
            }
        </>
    )
}
