//!SUNIL
// export const Base_URL = 'http://172.16.1.149:17148';
// export const sock_URL = 'ws://172.16.1.149:17148';



//!MAIN Client
// export const Base_URL = 'https://api.escapelyteambuilding.com';
// export const sock_URL = 'wss://api.escapelyteambuilding.com';


//!MAIN Client api1
export const Base_URL = 'https://api1.escapelyteambuilding.com';
export const sock_URL = 'wss://api1.escapelyteambuilding.com';


// export const Base_URL = 'https://api.escapelyteambuilding.com';
// export const sock_URL = 'wss://api.escapelyteambuilding.com';


// export const Base_URL = 'http://localhost:8001';
// export const sock_URL = 'http://localhost:8001';


//localhost
// export const Base_URL = 'http://172.16.11.19:8001';
// export const sock_URL = 'http://172.16.11.19:8001';