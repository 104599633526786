import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { getPodLeaderBoard } from '../Services/PodServices';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { toInteger } from 'lodash';
import useInterval from "../../../hooks/useInterval"

export default function LeaderBoard() {
    const navigate = useNavigate();
    const { podId } = useParams();
    const [data, setData] = useState([]);

    const handleBack = () => {
        navigate('/admin/pod');
    };




    const getLeaderBoardData = () => {
        getPodLeaderBoard({ _id: podId }).then((res) => {
            if (res.status === 200) {
                console.log(res.data.data);
                setData(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    let clearIntervalId = useInterval(() => {
        getLeaderBoardData();
        // if(data.every((el)=>el?.gameStatus == 2 || el?.gameStatus == 3)){

        // }
    }, 10000);


    useEffect(() => {
        getLeaderBoardData();
    }, [])



    const formatTime = (ms) => {
        let x = ms / 1000
        let seconds = Math.floor(x % 60)
        seconds = seconds.toString().length < 2 ? '0' + seconds : seconds
        x /= 60
        let minutes = Math.floor(x % 60)
        minutes = minutes.toString().length < 2 ? '0' + minutes : minutes
        x /= 60
        const hours = Math.floor(x % 24)
        const formattedTime = hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
        return formattedTime;
    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col md={11}>
                        {' '}
                        <Card.Title as="h5">Leaderboard</Card.Title>{' '}
                    </Col>
                    <Col md={1}>
                        <Button className="btn btn-primary btn-sm" onClick={() => handleBack()}>
                            BACK
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <TableContainer component={Paper} style={{ borderRadius: 0, padding: 0 }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Team Name </TableCell>
                                <TableCell>Team Members</TableCell>
                                <TableCell>Penalties</TableCell>
                                <TableCell>Reveal Answer Used</TableCell>
                                <TableCell>Game Status</TableCell>
                                <TableCell>Time Left</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Game Link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((val, i) => (
                                <TableRow key={i}>
                                    <TableCell>{val.teamName ? val.teamName : "-"}</TableCell>
                                    <TableCell style={{ wordBreak: "break-all" }}>{val.teamMembers.map((member) => (member.name)).join(", ")}</TableCell>
                                    <TableCell>{val.totalPenalties ? val.totalPenalties : "-"}</TableCell>
                                    <TableCell>{val.revealAnswerUsed ? val.revealAnswerUsed : "-"}</TableCell>
                                    <TableCell>{val.gameStatus === 2 ? "Finished" : "Not Finished"}</TableCell>
                                    <TableCell>{val.timeleft ? formatTime(val.timeleft) : "-"}</TableCell>
                                    <TableCell>{val.score ? formatTime(val.score) : "0"}</TableCell>
                                    <TableCell>
                                        <a href={val.gameLink + "/leaderboard"} target="_blank">View Link</a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card.Body>
        </Card>
    )
}
