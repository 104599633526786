import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { podAdd } from '../Services/PodServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Box, FormHelperText, Grid, InputLabel, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import { getGameName } from '../Services/GameServices';
import { DatePicker } from '@mui/lab'
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { addTimeToDate, getDateFormat, getFormatDate } from '../../../utils/formatNumber';

function getTimeComponents(date) {
  date = new Date(date)
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const format = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format if needed
  const displayHours = hours % 12 === 0 ? 12 : hours % 12;

  return {
    hours: displayHours,
    minutes: minutes < 10 ? `0${minutes}` : minutes,
    format,
  };
}


const AddPod = () => {
  const navigate = useNavigate();
  const [gameData, setGameData] = useState([]);
  const today = new Date()

  // .toLocaleString('en-US', {
  //   timeZone: 'America/Tijuana',
  //   year: 'numeric',
  //   month: 'numeric',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric',
  //   hour12: true, // Use 12-hour format
  //   // timeZoneName: 'short', // Display time zone abbreviation
  // })
  // // today.setHours(0, 0, 0, 0);
  // const { hours, minutes, format } = getTimeComponents(today);
  console.log(today)

  const getGameData = async () => {
    const result = await getGameName();
    setGameData(result?.data.data);
  };





  const PodSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').strict().trim("Trailing white spaces not allowed").min(1).max(60),
    // email: Yup.string().nullable().optional().matches(/^(?!\s+$).*/, "Spaces are not allowed").test("is-email", "Please enter valid email", (val) => {
    //   return val == undefined || complexEmailRegex(val)
    // }).max(255),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}(?:,[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9})*$/, 'Invalid email format'),
    totalTeams: Yup.number().min(1, "Total teams must be greater than or equal to 1").max(50, "Total teams must be less than or equal to 50").required('Total team is required'),
    gameId: Yup.string().required('Please select a game'),
    date: Yup.date().required('Start date is required').typeError("Please enter a valid date"),
    minutes: Yup.number().required("Minutes is required").strict().moreThan(0, "Please enter valid minutes"),
    time: Yup.object().shape({
      hours: Yup.string().required("Hours is required").test(
        'Is positive?',
        'Hours must be greater than or equals to zero',
        (value) => value >= 0
      ),
      minutes: Yup.string().required("Minutes is required").test(
        'Is positive?',
        'Minutes must be greater than or equals to zero',
        (value) => value >= 0
      ),
      format: Yup.string().required("Please select time format")
    })
  });



  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      totalTeams: "",
      gameId: "",
      minutes: "",
      date: today,
      todayDate: today,
      time: {
        hours: '12',
        minutes: '00',
        format: "AM"
      },
      // time: { hours, minutes, format }
    },

    validationSchema: PodSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let timeValue = { ...values.time };
        let d = addTimeToDate(getDateFormat(values.date), timeValue);
        // if (moment(d).isSameOrAfter(moment(new Date()).subtract(1, 'd'))) {
        const sendData = {
          name: values.name,
          email: values.email,
          totalTeams: values.totalTeams,
          gameId: values.gameId,
          date: d,
          time: timeValue,
          minutes: values.minutes,
        }

        console.log(sendData)


        const respData = await podAdd(sendData);

        if (respData.status === 200) {
          navigate('/admin/pod');
          toast.success(respData?.data.message);
        }
        // } else {
        //   setFieldError('date', "Date must not be before today")
        // }

      } catch (error) {
        setSubmitting(false);
        console.log(error)
        toast.error(error?.response.data.message);
      }

    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldError, getFieldProps, setValues, setFieldValue } = formik;

  const handleDateChange = (d) => {
    setValues({
      ...values,
      date: d
    })
  }

  const handleChangeTime = (e) => {
    let elementName = e.target.name;
    let elementValue = e.target.value;
    let timeReg = /^[0-59]$/
    if (elementName !== 'format') {
      if (elementValue !== "" && elementValue !== null && elementValue !== undefined) {

        if (elementName === 'hours') {
          if (parseInt(elementValue) >= 12) {
            elementValue = 12;
          }

        }
        if (elementName === 'minutes') {
          if (parseInt(elementValue) >= 59) {
            elementValue = 59;
          }

        }
      }
    }

    setValues({
      ...values,
      time: {
        ...values.time,
        [elementName]: elementValue
      }
    })

  }
  useEffect(() => {
    getGameData();
  }, []);
  const [input, setInput] = useState("")
  const handleGame = (e, value) => {
    console.log(value)
    setFieldValue("gameId", value.label)
    setInput(value.value)
  }

  console.log(gameData)
  const [dateOpen, setDateOpen] = useState(false);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Add Pod</Card.Title>
        </Card.Header>
        <Card.Body>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel>Pod Name</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    placeholder='Enter POD name'
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps('name')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Minutes*</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    placeholder='Enter Minutes'
                    value={values.minutes}
                    name="minutes"
                    type="number"
                    error={Boolean(touched.minutes && errors.minutes)}
                    helperText={touched.minutes && errors.minutes}
                    onWheel={event => event.target.blur()}
                    // onChange={(e)=>{handleTimeDuration(e)}} />    
                    {...getFieldProps('minutes')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Start Date*</InputLabel>
                  <FormControl fullWidth error={Boolean(touched.date && errors.date)}>
                    <DatePicker
                      name="date"
                      open={dateOpen}
                      onOpen={() => setDateOpen(true)}
                      onClose={() => setDateOpen(false)}
                      inputFormat='MM/d/yyyy'
                      value={values.date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField onClick={() => { setDateOpen(true) }} onKeyDown={(e) => { e.preventDefault(); }} variant='outlined' {...params} />}
                    />
                    <FormHelperText>{touched.date && errors.date}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Start Time*</InputLabel>
                  <Grid container spacing={2}>
                    <Grid item lg={4} xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="hours"
                        type="number"
                        placeholder='HH'
                        value={values.time.hours}
                        error={Boolean(touched?.time?.hours && errors?.time?.hours)}
                        helperText={touched?.time?.hours && errors?.time?.hours}
                        onChange={(e) => { handleChangeTime(e) }}
                        onWheel={event => event.target.blur()}
                      />
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="minutes"
                        type="number"
                        placeholder='MM'
                        value={values.time.minutes}
                        error={Boolean(touched?.time?.minutes && errors?.time?.minutes)}
                        helperText={touched?.time?.minutes && errors?.time?.minutes}
                        onWheel={event => event.target.blur()}
                        onChange={(e) => { handleChangeTime(e) }}
                      />
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <FormControl fullWidth error={Boolean(touched?.time?.format && errors?.time?.format)}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant='outlined'
                          name='format'
                          value={values.time.format}
                          onChange={(e) => { handleChangeTime(e) }}
                        >
                          <MenuItem value="AM">AM</MenuItem>
                          <MenuItem value="PM">PM</MenuItem>

                        </Select>
                        <FormHelperText>{touched?.time?.format && errors?.time?.format}</FormHelperText>

                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Game*</InputLabel>
                  <FormControl fullWidth error={Boolean(touched.gameId && errors.gameId)}>
                    {/* <Autocomplete
                     id="gameId"
                     variant='outlined'
                     name="gameId"
                     value={values.gameId}
                     onChange={(e,value) => handleGame(e,value)}
                     options={gameData.map(
                      (x) => ({
                        value: x._id,
                        label: x.internalTitle,
                      })
                    )}
                    //  sx={{ width: 400 }}
                     renderInput={(params) => <TextField {...params} 
                     placeholder="Select game"
                     renderOption = {(props, option)=>{
                      return(<li {...props} key={option._id}>{option.internalTitle}</li>)
                    }}
                     variant="outlined" fullWidth/>}
                  /> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant='outlined'
                      displayEmpty
                      label="Select Game"
                      value={values.gameId}
                      {...getFieldProps('gameId')}
                      style={{ color: values.gameId === "" ? 'gray' : 'black' }}
                    >
                      {gameData.length ?
                        <MenuItem disabled value="">Select Game</MenuItem>
                        : null
                      }
                      {gameData.map((data, idx) => (
                        <MenuItem key={idx} value={data._id}>{data.internalTitle}</MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{touched.gameId && errors.gameId}</FormHelperText>

                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Total Teams*</InputLabel>
                  <TextField
                    fullWidth
                    name="totalTeams"
                    variant='outlined'
                    placeholder='Enter Total teams'
                    type="number"
                    value={values.totalTeams}
                    error={Boolean(touched.totalTeams && errors.totalTeams)}
                    helperText={touched.totalTeams && errors.totalTeams}
                    onWheel={event => event.target.blur()}
                    {...getFieldProps('totalTeams')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Email</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    placeholder='Enter email'
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps('email')}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                <Button type="reset" onClick={() => { navigate("/admin/pod") }} className="btn btn-outline-secondary mr-3">
                  BACK
                </Button>
                <Button type="submit" className="btn btn-primary">
                  SAVE
                </Button>
              </Box>
            </Form>
          </FormikProvider>
        </Card.Body>
      </Card>

    </>
  );
};

export default AddPod;
