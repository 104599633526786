import React, { useEffect, useState, useContext, useRef } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Modal, ModalBody } from 'react-bootstrap'
import { Base_URL } from '../Config/Config';
import Draggable from "react-draggable";
import { motion, AnimatePresence } from 'framer-motion';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Skeleton from '@mui/material/Skeleton';

import OpenWithIcon from '@mui/icons-material/OpenWith';

import closeIcon from "../../../assets/images/close-icon.png";
import AudioPlayer from '../../../components/AudioPlayer';
import { SocketContext } from "../../../contexts/socketConnection";
import useDebounce from '../../../hooks/useDebounce';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useDraggable from '../../../hooks/useDraggable';
import CircularProgress from '@mui/material/CircularProgress';


// function loadImage(url) {
//     return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
//   }

const ClueViewer = ({ show, data, onClose, labels }) => {
    const [enlarge, setEnlarge] = useState(false);
    const [imageLoading, setImageLoading] = useState(false)
    const [dataUrl, setDataUrl] = useState('')
    const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 })
    const myElementRef = useRef(null);
    const imgRef = useRef(null)
    // const [elementHeight, setElementHeight] = useState(0);

    useEffect(() => {
        if (data.url !== dataUrl || dataUrl == '') {
            setDataUrl(data.url)
            setImageLoading(true)
        }
        const loadImage = (e) => {
            const height = myElementRef.current.offsetHeight;
            const width = myElementRef.current.offsetWidth;

            // Center the element both vertically and horizontally
            myElementRef.current.style.position = 'fixed';
            myElementRef.current.style.top = '50vh';
            myElementRef.current.style.left = '50vw';
            myElementRef.current.style.marginTop = `-${height / 2}px`;
            myElementRef.current.style.marginLeft = `-${width / 2}px`;
            myElementRef.current.style.opacity = 1;
            setImageLoading(false)
        }


        imgRef.current?.addEventListener("load", loadImage)
        return () => {
            imgRef.current?.removeEventListener("load", loadImage)
        }
    }, [show, data]);

    useEffect(() => {
        const loadImage = () => {
            const height = myElementRef.current.offsetHeight;
            const width = myElementRef.current.offsetWidth;

            // Center the element both vertically and horizontally
            myElementRef.current.style.position = 'fixed';
            myElementRef.current.style.top = '50vh';
            myElementRef.current.style.left = '50vw';
            // myElementRef.current.style.marginTop = `-${height / 2}px`;
            myElementRef.current.style.marginLeft = `-${width / 2}px`;
            myElementRef.current.style.opacity = 1;
        }
        loadImage()
    }, [enlarge])


    const [disable, setDisable] = useState(false);
    const onTouchEnd = useDebounce((e) => {
        e.stopPropagation();
        setDisable(false);
    }, 500);

    const handleDrag = (e) => {
        e.stopPropagation()
        setDisable(true)
    }

    const isTouchDevice = () => {
        return (
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        );
    };


    const audioContainerRef = useRef(null)

    return (
        data && <div>
            <Draggable
                handle=".handle"
                disabled={disable}
                position={null}
                grid={[25, 25]}
                scale={1}
                defaultPosition={{ x: 0, y: 0 }}
            >
                <div ref={myElementRef} className={show ? enlarge ? "clue-viewer modal show margin-2 enlrage" : "clue-viewer modal show margin-3" : "clue-viewer modal"} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className={(enlarge ? "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl handle" : "modal-dialog modal-dialog-scrollable modal-dialog-centered handle")} style={{ cursor: 'pointer' }}>
                        <div className="modal-content">
                            <div className="modal-body" style={{ backgroundColor: "#1D1C1C", color: "#FFF", textAlign: 'center', padding: '5px 3px' }}>
                                <Box style={{ display: "flex", padding: "2%", backgroundColor: "#1D1C1C", color: "#FFFB00", justifyContent: "space-between", cursor: 'pointer' }}>
                                    <Box>
                                        {enlarge ?
                                            <FullscreenExitIcon onClick={() => { setEnlarge(false) }} onTouchEnd={() => { setEnlarge(false) }} style={{ marginRight: "10px", paddingLeft: '4px', cursor: 'pointer' }} />
                                            :
                                            <>
                                                {/* <OpenWithIcon style={{ marginRight: "10px", cursor: 'pointer' }} className="handle" /> */}
                                                <FullscreenIcon onClick={() => { setEnlarge(true) }} onTouchEnd={() => { setEnlarge(true) }} style={{ cursor: 'pointer' }} />
                                            </>
                                        }
                                    </Box>

                                    <Typography style={{ color: "#FFFB00", gap: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { onClose() }} onTouchEnd={() => { onClose() }}>
                                        <img src={closeIcon} style={{ cursor: 'pointer', height: '20px' }} />
                                        <span style={{ color: "#FFF", letterSpacing: '0.32px', fontSize: '14px' }}>{labels?.closeButton}</span>
                                    </Typography>
                                </Box>
                                <h5 className='text-center text-white' style={{ padding: '5px 0px 15px', textTransform: 'uppercase', letterSpacing: '0.01px', fontFamily: 'PFDinMonoMedium', fontSize: '20px' }}>{data.title}</h5>
                                {data.mediaType && (data.mediaType).split("/")[0] === "video" ?
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <video width="100%" key={data._id} preload="auto" controlsList="nodownload" controls className="card-media"
                                            onDrag={() => setDisable(false)}
                                            onMouseDown={() => setDisable(true)}
                                            onMouseLeave={() => setDisable(false)}>
                                            <source src={`${Base_URL}/${data.url}`}></source>
                                        </video>
                                    </Box>
                                    : data?.mediaType && (data.mediaType).split("/")[0] === "image" ?
                                        <>
                                            <div style={{ minHeight: "300px", position: "relative" }}>
                                                {/* {imageLoading ? <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                                    : <Box
                                                        component="img"
                                                        alt="file preview"
                                                        src={`${Base_URL}/${data.url}`}
                                                        className='clue-image'
                                                        sx={{
                                                            objectFit: 'fit',
                                                            padding: '2px 0px 0px px',
                                                            width: '100%',
                                                        }}
                                                        onLoad={() => setImageLoading(true)}
                                                        onDragStart={(e) => e.preventDefault()}
                                                    />
                                                } */}
                                                {imageLoading && <Box sx={{
                                                    display: 'flex', position: "absolute", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", backdropFilter: "blur(10px)",
                                                    backgroundColor: 'rgba(0,0,30,0.5)'
                                                }}>
                                                    <CircularProgress sx={{ color: "#fff" }} />
                                                </Box>}

                                                <Box
                                                    component="img"
                                                    alt="file preview"
                                                    ref={imgRef}
                                                    loading="lazy"
                                                    src={`${Base_URL}/${data.url}`}
                                                    className='clue-image'
                                                    sx={{
                                                        objectFit: 'fit',
                                                        padding: '2px 0px 0px px',
                                                        width: '100%',
                                                    }}
                                                    onLoad={() => setImageLoading(true)}
                                                    onDragStart={(e) => e.preventDefault()}
                                                />


                                            </div>
                                        </>
                                        : data?.mediaType && (data.mediaType).split("/")[0] === "audio" ?
                                            <div id="hint-need-audio" className="hint-need-audio" style={{ padding: '0px 12px' }}
                                                onDrag={handleDrag}
                                                onMouseDown={handleDrag}
                                                onMouseLeave={(e) => {
                                                    e.stopPropagation()
                                                    setDisable(false)
                                                }}
                                                onTouchStart={handleDrag}
                                                onTouchMove={handleDrag}
                                                onTouchEnd={onTouchEnd}
                                                ref={audioContainerRef}
                                            // onTouchCancel={onTouchEnd}
                                            >
                                                <AudioPlayer src={`${Base_URL}/${data.url}`} setDisable={setDisable} />
                                            </div>
                                            :
                                            <>
                                                {data.text &&
                                                    <Box className='text-center p-3'>
                                                        <Typography>{data.text}</Typography>
                                                    </Box>
                                                }
                                            </>
                                }
                                <p className='mt-3'>{data.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>

    )
}

export default React.memo(ClueViewer)