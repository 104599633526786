import React, { useEffect, useState } from "react";
// import starIcon from "../../../assets/images/star-icon.png";
// import starIcon from "../../../assets/images/star-icon.svg";
import StarIcon from "../../../assets/images/StarIcon"
import smallStar from "../../../assets/images/small-star.png";
import logo from "../../../assets/images/logo.svg";
import { GetLeaderBoard, checkValidLeaderboard, getPodName } from "../Services/Service";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { toInteger } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { GetPodNames, LabelGetList } from "../../Admin/Services/LableServices";
import { toast } from "react-toastify";





const LeaderBoard = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  let { podId, token, gameId } = useParams();
  const reduxlabelData = useSelector((state) =>
    state.game.data &&
      state.game.data.labelData &&
      Object.keys(state.game.data.labelData).length
      ? state.game.data.labelData
      : {}
  );


  const checkValidLeaderBoardURL = async () => {
    try {
      let resp = await checkValidLeaderboard({ podId, token, gameId });
      if (resp.status === 200) {
        console.log("valid");
      }
    } catch (err) {
      if (err.response.status === 419) {
        console.log(err, "invalid 419")
        navigate("/404", { state: { pod_id: podId, game_id: gameId } });
      }

      // if (err.response.status === 400) {
      //   console.log(err, "invalid 400")
      //   navigate("/404", { state: { pod_id: podId, game_id: gameId } });
      // }
      // toast.error(err.response.data.message)
    }
  };


  useEffect(() => {
    checkValidLeaderBoardURL();
  }, [])

  const [labelData, setLabelData] = useState(reduxlabelData)

  const teamDetails = {
    teamName: "Squashed Remote Control",
    players: [
      { name: "Henry Buckley" },
      { name: "Ricky Franco" },
      { name: "Alice Harbin" },
      { name: "Hazel Ward" },
      { name: "Barbara Hammett" },
    ],
    score: "30",
    penalties: "02",
    revealAnswerUsed: "2",
  };
  const { state } = useLocation()
  console.log(state)
  // , totalTime: state.totalPodTime
  const [podData, setPodData] = useState({})
  const getData = async () => {
    const res = await getPodName({ _id: podId, gameId: gameId });
    const data = res?.data?.labelData
    setLabelData(data);
  }

  useEffect(() => {
    document.body.classList.add('loading-indicator');
    console.log({ podId, token, gameId }, labelData)
    console.log("label", labelData)
    if (Object.keys(labelData).length == 0) {
      getData()
    }

    GetLeaderBoard({ _id: podId }, token).then((res) => {
      if (res.status === 200) {
        console.log(res.data.data);
        setData(res.data.data)
      }
      document.body.classList.remove('loading-indicator');
    }).catch((err) => {
      toast.error("An Error occured while loading the leaderboard")
      document.body.classList.remove('loading-indicator');
    })

  }, [])

  // const formatTime = (ms) => {
  //   let x = ms / 1000
  //   const seconds = x % 60
  //   x /= 60
  //   const minutes = x % 60
  //   x /= 60
  //   const hours = x % 24
  //   x /= 24
  //   const days = x
  //   console.log(toInteger(seconds).toString().length <= 1)
  //   // return ms;
  //   return `${toInteger(hours) ? toInteger(hours) + ":" : ""}${toInteger(minutes) ? toInteger(minutes) + ":" : ""}${toInteger(seconds).toString().length <= 1 ? "0" + toInteger(seconds) : toInteger(seconds)}`
  // }


  const formatTime = (ms) => {
    let x = ms / 1000
    let seconds = Math.floor(x % 60)
    seconds = seconds.toString().length < 2 ? '0' + seconds : seconds
    x /= 60
    let minutes = Math.floor(x % 60)
    minutes = minutes.toString().length < 2 ? '0' + minutes : minutes
    x /= 60
    const hours = Math.floor(x % 24)
    const formattedTime = hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
    return formattedTime;
  }

  return (
    <div className="form-bg">
      <div className="leader-bored">
        <div className="logo text-center">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="leader-bored-bg">
            <div className="leader-bored-head text-center">
              <h3 className="leader-bored-title pb-3">{labelData?.LeaderboardTitle}</h3>
              <div className="star-number">
                <figure>
                  {/* <img src={starIcon} /> */}
                  <StarIcon width="44px" />
                </figure>
                <span>1</span>
              </div>
              <h3 className="leader-bored-title">{data[0]?.teamName}</h3>
              <ul className="leader-bored-list pt-2 pb-2">
                {data[0]?.teamMembers.map((members, idx) => {
                  return <li key={idx}>{members.name}</li>;
                })}
              </ul>
              <ul className="leader-bored-info">
                <li>
                  <b>{labelData?.Totaltime}:</b>
                  {data[0]?.score != 0 ? formatTime(data[0]?.score) : "0"}
                </li>
                <li>
                  <b>{labelData?.penaltiesLabel}:</b>
                  {data[0]?.totalPenalties} {labelData?.mins}
                </li>
                <li>
                  <b>{labelData?.RevealAnswers}:</b>
                  {data[0]?.revealAnswerUsed}
                </li>
              </ul>
            </div>
            <div className="row">
              {data.length > 1 ? data.map((item, idx) => {
                return (idx > 0 ? (<div key={idx} className="col-md-6">
                  <div className="leader-bored-box">
                    <div className="leader-bored-box-L">
                      <figure style={{ marginBottom: "4px" }}>
                        <StarIcon width="23px" />
                      </figure>
                      <span>{idx + 1}</span>
                    </div>
                    <div className="leader-bored-box-R">
                      <div className="leader-bored-box-R-head">
                        <h3>{item.teamName}</h3>
                        {item.gameStatus === 2 ? <span>{labelData?.Finished}</span> : <span className="not-finished">{labelData?.Notfinished}</span>}
                      </div>
                      <ul className="leader-bored-list">
                        {item.teamMembers.map((members, idx) => {
                          return <li key={idx}>{members.name}</li>;
                        })}
                      </ul>
                      <ul className="leader-bored-info">
                        <li>
                          <b>{labelData?.Totaltime}</b>
                          {item?.score != 0 ? formatTime(item?.score) : "0"}
                          {/* {formatTime(item.score)} */}
                        </li>
                        <li>
                          <b>{labelData?.penaltiesLabel}:</b>
                          {item.totalPenalties} {labelData?.mins}
                        </li>
                        <li>
                          <b>{labelData?.RevealAnswers}:</b>
                          {item.revealAnswerUsed}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>) : null)
              }) : null}
              {/* <div className="col-md-6">
                <div className="leader-bored-box">
                  <div className="leader-bored-box-L">
                    <figure>
                      <img src={smallStar} />
                    </figure>
                    <span>3</span>
                  </div>
                  <div className="leader-bored-box-R">
                    <div className="leader-bored-box-R-head">
                      <h3>{teamDetails.teamName}</h3>
                      <span className="not-finished">NOT FINISHED</span>
                    </div>
                    <ul className="leader-bored-list">
                      {teamDetails.players.map((members, idx) => {
                        return <li key={idx}>{members.name}</li>;
                      })}
                    </ul>
                    <ul className="leader-bored-info">
                      <li>
                        <b>Score:</b>
                        {teamDetails.score}
                      </li>
                      <li>
                        <b>Penalties:</b>
                        {teamDetails.penalties}
                      </li>
                      <li>
                        <b>Reveal Answer used:</b>
                        {teamDetails.revealAnswerUsed} times
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
