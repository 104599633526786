import React, { useRef, useEffect, useState } from 'react';
import { podAll, podDelete } from '../Services/PodServices';
import { getGameName } from '../Services/GameServices';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Card, Modal, Button } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@material-ui/core';
import { getDate, getDateFormat } from '../../../utils/formatNumber';

const Pod = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const [gameData, setGameData] = useState([]);
  const [deletePod, setDeletePod] = useState('');
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterDate, setFilterDate] = useState();


  const handleViewLeaderBoard = (id) => {
    navigate(`/admin/pod/${id}/leaderboard`);
  }
  const column = [
    {
      title: 'Pod Name',
      field: 'name',
      sorting: true
    },
    { title: 'Total Teams', field: 'totalTeams', sorting: false },

    { title: 'Game Name', field: 'gameId', sorting: false, render: (rowData) => rowData?.gameId?.internalTitle },
    {
      title: 'Start Date', sorting: false, render: (rowData) => (
        `${getDateFormat(rowData.date)}`
      )
    },
    {
      title: 'Time Duration', field: 'minutes', sorting: false, render: (rowData) => (
        <span>
          {`${rowData.minutes < 10 ? '0' + rowData.minutes : rowData.minutes} min`}
        </span>
      )
    },
    {
      title: 'Start Time', sorting: false, render: (rowData) => (
        `${rowData?.time?.hours}:${rowData?.time?.minutes} ${rowData?.time?.format}`
      )
    },
    // { title: 'End Time', sorting:false, render: (rowData) => `${rowData.endTime.hours<10 ? '0'+rowData.endTime.hours : rowData.endTime.hours}:
    // ${rowData.endTime.minutes<10 ? '0'+rowData.endTime.minutes : rowData.endTime.minutes}`},

    {
      title: 'Action',
      field: 'action',
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <Tooltip title="View Leaderboard">
              <LeaderboardIcon onClick={() => handleViewLeaderBoard(rowData?._id)} style={{ marginRight: '10px', cursor: 'pointer' }} />
            </Tooltip>

            <Tooltip title="View Pod">
              <VisibilityIcon className='view-icon' onClick={() => handlePodView(rowData)} style={{ marginRight: '10px', cursor: 'pointer' }} />
            </Tooltip>

            <Tooltip title="Edit">
              <EditIcon onClick={() => handlePodEdit(rowData)} style={{ marginRight: '10px', cursor: 'pointer' }} />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                className='dlt-icon'
                onClick={() => {
                  handleDelete(rowData._id);
                }}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  const DateFormat = (date) => {
    return date ? format(new Date(date), "yyyy-MM-dd") : "-";
  }

  const getData = async (query) => {
    let body;
    const { page, orderBy, orderDirection } = query;
    if (filterData?.length) {
      body = {
        search: query?.search,
        pageNumber: query?.page + 1,
        pageSize: query?.pageSize,
        gameId: filterData,
        sortBy: orderBy?.field ? orderBy?.field : "date",
        sortOrder: orderDirection === 'asc' ? 1 : -1,
        startDate: startDate ? DateFormat(startDate) : "",
        endDate: endDate ? DateFormat(endDate) : ""
      };
    } else {
      body = {
        search: query?.search,
        pageNumber: query?.page + 1,
        pageSize: query?.pageSize,
        sortBy: orderBy?.field ? orderBy?.field : "date",
        sortOrder: orderDirection === 'asc' ? 1 : -1,
        dateStart: endDate && startDate ? DateFormat(startDate) : "",
        dateEnd: endDate ? DateFormat(endDate) : ""
      };
    }

    if (query.filters.length) {
      query.filters.map((data) => {
        if (data.value.length === 1) {
          body[data.column.field] = data.value[0];
        }
      });
    }
    const response = await podAll(body);

    const data = response?.data?.data?.result;

    const totalCount = response?.data?.data?.totalPods;
    return {
      data,
      page,
      totalCount
    };
  };
  const option = gameData.map((game) => ({
    key: game.internalTitle,
    id: game._id
  }));
  useEffect(() => {
    getGameData();
  }, []);
  const getGameData = async () => {
    const result = await getGameName();
    setGameData(result?.data.data);
    console.log(result?.data.data)
    localStorage.setItem("gameData", result?.data.data.name)
  };
  const handlePodView = (data) => {
    navigate('/admin/podoneview', { state: data._id });
  };
  const handlePodEdit = (data) => {
    navigate('/admin/editpod', { state: data });
  };
  const handleDelete = (deleteId) => {
    setShow(true);
    setDeletePod(deleteId);
  };
  const handleAdd = () => {
    navigate('/admin/addpod');
  };
  const handleFilter = async (selectedList, selectedItem) => {
    setFilterData((prev) => [...prev, selectedItem.id]);
    tableRef.current.onQueryChange();
  };
  const filterRemove = (selectedList, removedItem) => {
    const index = filterData.indexOf(removedItem.id);
    filterData.splice(index, 1);
    tableRef.current.onQueryChange();
  };

  const handleClose = () => {
    setShow(false);
  };
  const handlePodDelete = async () => {
    setShow(false);
    try {
      const podDeleteData = await podDelete({ _id: deletePod });
      tableRef.current.onQueryChange();
    } catch (err) { }
  };


  return (
    <div>
      {console.log("lastElement", moment(filterDate).format())}
      <Card style={{ padding: '0px' }}>
        <Card.Header>
          <div className="row">
            <div className="col-sm-9">
              <Card.Title as="h5">Pod List</Card.Title>
            </div>
            <div className="col-sm-3  justify-content-end">
              <Multiselect
                className="filter"
                name="gameId"
                displayValue="key"
                placeholder="Game Filter"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={(selectedList, removedItem) => filterRemove(selectedList, removedItem)}
                onSearch={function noRefCheck() { }}
                onSelect={(selectedList, selectedItem) => handleFilter(selectedList, selectedItem)}
                showCheckbox
                options={option}
                style={{
                  chips: {
                    minWidth: "25px"
                  },
                  searchBox: {
                    display: "flex",
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: "1px solid #1d1c1c",
                    flexDirection: "row",
                    minWidth: "150px",
                    flexWrap: "wrap",
                    justifyContent: "justify"
                  }
                }}
              />
            </div>
          </div>
        </Card.Header>

        <div className='date-filter-box'>
          <div className='row'>
            <div className='col-md-8'>
              <div className="date-picker-box" style={{ display: "flex", alignItems: "center", position: "relative", left: "25px", zIndex: 1000 }}>
                <div>
                  <DatePicker
                    className='form-control'
                    onChangeRaw={(e) => e.preventDefault()}
                    onChange={(date) => { setStartDate(date); }}
                    selected={startDate}
                    value={startDate}
                    placeholderText="Select Start Date"
                    timeIntervals={5}
                    isClearable
                    // clearButtonClassName="date-picker__clear-btn"
                    customInput={<TextField value={startDate} />}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                  <h4 className="m-2"> To </h4>
                </div>
                <div>
                  <DatePicker
                    className='form-control'
                    onChangeRaw={(e) => e.preventDefault()}
                    onChange={(newVal) => { setEndDate(newVal); tableRef.current.onQueryChange(); }}
                    selected={endDate}
                    placeholderText="Select End Date"
                    timeIntervals={5}
                    isClearable
                    customInput={<TextField value={endDate} />}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Multiselect
                className="filter"
                name="gameId"
                displayValue="key"
                placeholder="Game Filter"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={(selectedList, removedItem) => filterRemove(selectedList, removedItem)}
                onSearch={function noRefCheck() { }}
                onSelect={(selectedList, selectedItem) => handleFilter(selectedList, selectedItem)}
                showCheckbox
                options={option}
                style={{
                  chips: { minWidth: "25px" },
                  searchBox: {
                    display: "flex",
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: "1px solid #1d1c1c",
                    flexDirection: "row",
                    minWidth: "150px",
                    flexWrap: "wrap",
                    justifyContent: "justify"
                  }
                }}
              />
            </div>
          </div>
        </div>


        <div>
          <MaterialTable
            tableRef={tableRef}
            title={
              <Button className="btn btn-primary" onClick={() => handleAdd()}>
                ADD{' '}
              </Button>
            }
            columns={column}
            data={getData}
            options={{
              search: true,
              emptyRowsWhenPaging: false,
              headerStyle: { fontWeight: 'bold' },
              draggable: false,
            }}
          />
        </div>
      </Card>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline-secondary" onClick={handleClose}>
            NO
          </Button>
          <Button className="btn btn-primary" onClick={handlePodDelete}>
            YES
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pod;
