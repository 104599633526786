import { replace } from 'lodash';
// import moment from 'moment';
import moment, { months } from 'moment-timezone';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}



export const getFormatDate = (e) => {
  let time = {}
  const d = new Date(e);
  time.hours = d.getHours();
  time.minutes = d.getMinutes();
  // time.seconds = d.getSeconds();
  // Add leading zeros if needed

  //! AM PM
  const hours = time.hours;
  const ampm = hours >= 12 ? 'PM' : 'AM';
  time.format = ampm;


  const hours12 = hours % 12 || 12;
  time.hours = hours12

  time.hours = time.hours < 10 ? `0${time.hours}` : time.hours;
  time.minutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes;


  return time;
}

export const getPSTDate = (date) => {
  const pstTime = moment().tz('America/Tijuana')
                          .set({
                            year: date.getFullYear(), 
                            month: date.getMonth(),   
                            date: date.getDate(),    
                            hours: 0,   
                            minutes: 0  
                          });
  return pstTime
}











//!Time 
export function convertISOToPST(isoDateString) {

  const inputDate = new Date(isoDateString);

  const options = { timeZone: 'America/Los_Angeles', hour12: false };
  const pstDateString = inputDate.toLocaleString('en-US', options);

  return pstDateString;
}





// export function addTimeToDate(date, timeValue) {
//   let { hours, minutes, format } = timeValue;
//   if (format === 'PM' && hours !== '12') {
//     hours = Number(hours) + 12;
//   } else if (format === 'AM' && hours === '12') {
//     timeValue.hours = "00"
//     hours = 0;
//   } else {
//     hours = Number(hours);
//   }
//   // Parse the combined string to create a Moment object
//   const userDateTime = moment(date)
//     .set({ hours, minutes })
//   //.format('DD/MM/YYYY HH:mm');;

//   // Set the time zone to Pacific Time (GMT-8)
//   // const pacificTimezone = 'America/Tijuana';
//   // const pacificDateTime = userDateTime.tz(pacificTimezone).format();

//   // // Get the milliseconds representation of the Pacific Time
//   // const pacificTimeMilliseconds = userDateTime.tz(pacificTimezone).valueOf();

//   return userDateTime;
// }

// export function addTimeToDate(orgdate, timeValue) {
//   const { hours, minutes, format } = timeValue;

//   // // Clone the original date to avoid modifying it directly
//   let date = new Date(orgdate);
//   // // const date = moment(orgdate).utcOffset('-08:00').format('MM/D/YYYY')

//   // // Parse hours to 24-hour format if the format is 'PM'
//   if (format === 'PM' && hours !== '12') {
//     date.setHours(Number(hours) + 12);
//   } else if (format === 'AM' && hours === '12') {
//     timeValue.hours = "00"
//     date.setDay(date.getDay() + 1);
//     date.setHours(0);
//   } else {
//     date.setHours(Number(hours));
//   }

//   date.setMinutes(Number(minutes));

//   console.log(`🥹🥹`, { orgdate, date, hours, minutes, format })


//   // Set the updated hours and minutes on values.date
//   // date.setHours(date.getHours(), date.getMinutes());
//   // let h1 = timeValue.format === "AM" ? (timeValue.hours === '12' ? '00' : timeValue.hours) : (timeValue.hours != '12' ? parseInt(timeValue.hours) + 12 : timeValue.hours);
//   //  date = (`${moment(orgdate).format("YYYY-MM-DD")}T${h1}:${timeValue.minutes}:00.000Z`);
//   // console.log("++++++  ++ Date", date, date.toISOString());
//   // Return the updated values
//   // Converting to ISO string in Pitcairn Standard Time
//   // const pitcairnISOString = date.toLocaleString('en-US', {
//   //   timeZone: 'America/Tijuana',
//   //   timeZoneName: 'short',
//   //   weekday: 'short',
//   //   year: 'numeric',
//   //   month: 'short',
//   //   day: 'numeric',
//   //   hour: 'numeric',
//   //   minute: 'numeric',
//   //   second: 'numeric',
//   // });
//   // console.log(pitcairnISOString, date)
//   return date
// }


export const convertHour24 = (hours,format)=>{
  let hours24 = Number(hours)
  if(format === 'PM'){
    if(hours24 >=1 && hours24 < 12){
      hours24 = hours24 + 12
    }
  }else if(format === 'AM' && hours24 == 12){
    hours24 = 0
  } 
  return hours24
}

let timezone = 'America/Tijuana'
/**
 * @orgdate: MM/D/YYYY
 * timeValue: {minutes: 0, seconds: 0,hours:0} 
 */

export function addTimeToDate(orgdate,timeValue) {
  let  { hours, minutes, format } =timeValue
    // orgdate = getDate(orgdate)
  let date = orgdate.split("/") //[MM,D,YYYY]
  
  let utcDate = moment().tz(timezone)
              .set({
                month: date[0] -1,   
                date: date[1],     
                year: date[2], 
                hours: convertHour24(hours,format),   
                minutes: minutes, 
                seconds: 0 
              });
    
  return utcDate 
}


 
export function getDate(dateStr = null) {
  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
 if (isDST && !isDSTNow) {
   date.subtract(1, "hour");
 } else if (!isDST && isDSTNow) {
   date.add(1, "hour");
 }

  return date.toDate();
}



export function getDateFormat(dateStr = null) {
  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
//  if (isDST && !isDSTNow) {
//    date.subtract(1, "hour");
//  } else if (!isDST && isDSTNow) {
//    date.add(1, "hour");
//  }
  console.log(date)
  return date.format("MM/D/YYYY");
}

export function setTimeZero(dateStr = null) {
  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
//  if (isDST && !isDSTNow) {
//    date.subtract(1, "hour");
//  } else if (!isDST && isDSTNow) {
//    date.add(1, "hour");
//  }
  console.log(date)
  return date.format("MM/D/YYYY");
}

// export function addTimeToDate(orgdate, timeValue) {
//   const { hours, minutes, format } = timeValue;
  
//   return {
//     date:orgdate.getDate()
//     months:orgdate.getMonths()
//   }
// }

// export function addTimeToDate(orgdate, timeValue) {
//   try {
//     const { hours, minutes, format } = timeValue;

//     const date = moment(orgdate).utc();

//     // Parse hours to 24-hour format if the format is 'PM'
//     if (format === 'PM' && hours !== '12') {
//       date.add(Number(hours) + 12, 'hours');
//     } else if (format === 'PM' && hours === '12') {
//       date.add(1, 'day');
//       date.set({ hours: 0, minutes: 0 });
//       timeValue.format = "AM";
//       timeValue.hours = "00";
//     } else if (format === 'AM' && hours === '12') {
//       date.set({ hours: 0, minutes: 0 });
//     } else {
//       date.set({ hours: Number(hours), minutes: Number(minutes) });
//     }

//     // Converting to GMT -8 for display
//     const gmtMinus8Time = date.tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss');

//     console.log("Updated Date:", date, gmtMinus8Time);

//     return gmtMinus8Time;
//   } catch (error) {
//     console.error("Error in addTimeToDate function:", error);
//     // Handle the error or throw it to the calling code
//     throw error;
//   }
// }

