import React, { useState, useEffect, useRef } from 'react';

function useInterval(callback, delay) {
    const savedCallback = useRef();
    const intervalId = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            intervalId.current = setInterval(tick, delay);
            return () => clearInterval(intervalId.current);
        }
    }, [delay]);

    // Expose a function to stop the interval
    const stopInterval = () => {
        clearInterval(intervalId.current);
    };

    return stopInterval;
}


export default useInterval